var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("LandingHeader"),
      _c("OtherHero", { attrs: { heading: "About" } }),
      _vm._m(0),
      _c("LandingFooter"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "other-container" }, [
      _c("div", { staticClass: "content-box" }, [
        _c("p", [
          _vm._v(
            " SyncGrades (Lilo Consulting, LLC) was founded by Jed Lippold and Paul LoBoen, two former NYC educators with a shared passion for improving educational outcomes through innovative technology. With over two decades of experience across elementary, middle, and high school levels, our founders bring a deep understanding of the challenges faced by educators, students, and administrators. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " Jed Lippold, a former teacher and Program Chair at Lehman High School in the Bronx, and Paul LoBoen, a former teacher, school, district, and central-level administrator, and assistant superintendent, are the original creators of Skedula, Pupilpath, and PADS, which was the largest grading platform in the United States. These groundbreaking platforms revolutionized how schools managed data and communication, culminating in their successful sale in 2016. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " In 2018, Jed and Paul launched SyncGrades with a vision to simplify and streamline the complex, often siloed, data systems that burden educators. Today, SyncGrades serves schools and districts across New York State and Massachusetts, providing tools that make grading, analytics, and data management more accessible and user-friendly for teachers, administrators, parents, and students alike. "
          ),
        ]),
        _c("p", [
          _vm._v(
            " At SyncGrades, we are dedicated to empowering the educational community by bridging the gap between data and actionable insights, ensuring that every stakeholder has the resources they need to foster student success. "
          ),
        ]),
        _c("p"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }